let map;

async function locationMap(propertyMap) {
	const { Map } = await google.maps.importLibrary("maps")

	const mapLat = Number(propertyMap.dataset.lat),
		mapLng = Number(propertyMap.dataset.lng)

	map = new Map(propertyMap, {
		center: {
			lat: mapLat,
			lng: mapLng
		},
		zoom: 10,
		mapTypeId: 'roadmap',
		disableDefaultUI: true,
		zoomControl: true,
		zoomControlOptions: {
			position: google.maps.ControlPosition.TOP_RIGHT,
		  },
	})
	let weStayCol = '#EFB7BE'
		weStayCol = '#115F6C'
	const propertyCircle = new google.maps.Circle({
		strokeColor: weStayCol,
		strokeOpacity: 1,
		strokeWeight: 2,
		fillColor: weStayCol,
		fillOpacity: 0.3,
		map,
		center: map.center,
		radius: 8000
	})
	if(window.innerWidth > 767) {
		map.setZoom(11)
	}
}

{
	const propertyMap = document.getElementById("map")
	if(propertyMap){
		locationMap(propertyMap)
	}
	const locationField = document.querySelector('#locationSearch')
	if(locationField) {
		initAutocomplete()
	}
}


function initAutocomplete() {
	const locationField = document.querySelector('#locationSearch')
	if(locationField) {

		let currentLocation = (window.localStorage.getItem("searchLocation")) ? window.localStorage.getItem("searchLocation") : '';
		if(currentLocation) {
			locationField.parentElement.classList.add('hasValue')
			locationField.value = currentLocation
		}

		locationField.addEventListener('click', () => {
			closeAllSelectors()
		})
		locationField.addEventListener("change", () => {
			if(locationField.value === '') {
				locationField.parentElement.classList.remove('hasValue')
				window.localStorage.setItem("searchLocation", '')
				window.localStorage.setItem("searchLat", '')
				window.localStorage.setItem("searchLng", '')
			}
		})

		const options = {
			componentRestrictions: { country: ["uk"] },
			fields: ["geometry"],
		}

		let defaultBounds = new google.maps.LatLngBounds(
			new google.maps.LatLng(59.53530451232491, 8.968523437500039),
			new google.maps.LatLng(49.383639452689664, -17.39866406249996)
		);

		const options2 = {
			bounds: defaultBounds
		}

		const searchBox = new google.maps.places.SearchBox(locationField, options2)

		searchBox.addListener("places_changed", () => {
			locationField.parentElement.classList.add('hasValue')
			const places = searchBox.getPlaces()
			if(places.length == 0) {
				window.localStorage.setItem("searchLocation", '')
				window.localStorage.setItem("searchLat", '')
				window.localStorage.setItem("searchLng", '')
				return
			}

			places.forEach( (place) => {
				if(!place.geometry || !place.geometry.location) return
				let lat = place.geometry['location'].lat(),
					lng = place.geometry['location'].lng(),
					location = locationField.value
				window.localStorage.setItem("searchLocation", location)
				window.localStorage.setItem("searchLat", lat)
				window.localStorage.setItem("searchLng", lng)
			})
		})
		
	}
}

function clearLocation() {
	
	const locationField = document.querySelector('#locationSearch')
	locationField.value = ''
	window.localStorage.setItem("searchLocation", '')
	window.localStorage.setItem("searchLat", '')
	window.localStorage.setItem("searchLng", '')
	locationField.parentElement.classList.remove('hasValue')
}

{
	const clearLocationBtn = document.querySelector('#clearLocation')
	if(clearLocationBtn) {
		clearLocationBtn.addEventListener('click', () => {
			clearLocation()
		})
	}
}