class BookingDrawer {
	constructor(container) {
		this.container = container
		if(!this.container) return

		this.controls = this.container.querySelector('.mobile_controls')
		this.btn = this.controls.querySelector('button')
		this.widget = this.container.querySelector('.booking_widget')

		this.init()
	}

	bindAll() {
		["toggleWidget"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}

	setup() {
		this.container.classList.add('js-active')
	}

	toggleWidget(event) {
		event.preventDefault()
		let label = (this.container.classList.contains('show')) ? 'Check Availability' : 'Close'
		this.container.classList.toggle('show')
		this.btn.innerText = label
		setTimeout( () => {
			this.formHeight()
		}, 500)
	}

	formHeight() {
		let height = this.container.getBoundingClientRect().height
		document.documentElement.style.setProperty('--bookingHeight', height + 'px');
		console.log('here', height)
	}


	init() {
		this.bindAll()
		this.setup()
		this.btn.addEventListener('click', this.toggleWidget)
		console.log(this.container)
	}
}

const bookingDraw = document.querySelector('.booking_form')
const bookingDrawer = new BookingDrawer(bookingDraw)

class BookingPost {
	constructor(btn, form) {
		this.btn = btn
		this.form = form
		if(!this.btn) return 
		if(!this.form) return
		
		this.propertyID = this.btn.dataset.propertyId
		this.requestComplete = true
		this.init()
	}

	bindAll() {
		["bookNow"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}

	getDateBreakdown(date) {
		let year = date.getFullYear(),
			month = ('0' + (date.getMonth() + 1)).slice(-2),
			day = ('0' + date.getDate()).slice(-2)
			
		month = date.toLocaleString('default', { month: 'long' })
		
		return day + ' ' + month + ' ' + year 
	}

	getData() {
		this.data = {
			propertyID: this.propertyID,
			startDate: (window.localStorage.getItem(('startDateSelected'))) ? this.getDateBreakdown(new Date(window.localStorage.getItem(('startDateSelected')))) : '',
			endDate : (window.localStorage.getItem(('endDateSelected'))) ? this.getDateBreakdown(new Date(window.localStorage.getItem(('endDateSelected')))) : '',
			guestsAdults : (window.localStorage.getItem("filterAdults") != null) ? Number(window.localStorage.getItem("filterAdults")) : '',
			guestsChildren : (window.localStorage.getItem("filterChildren") != null) ? Number(window.localStorage.getItem("filterChildren")) : '',
			guestsPets : (window.localStorage.getItem("filterPet") != null) ? Number(window.localStorage.getItem("filterPet")) : '',
			guestsTotal : 0,
			quoteID : this.quoteID
		}
		this.data.guestsTotal = this.data.guestsAdults + this.data.guestsChildren
		this.updateBookBtn()
		this.buildRequest()
	}

	buildRequest() {
		if(!this.form) return
		const fields = this.form.querySelectorAll('input')
		if(!fields) return 
		fields.forEach( (el) => {
			let id = el.id
			el.value = this.data[id]
		})
	}

	newQuote() {
		this.quoteID = ''
		this.getData()
	}

	quoteIDUpdate() {
		this.quoteID = document.querySelector('p#quoteIDapi').dataset.id
		this.getData()
	}

	bookNow(e) {
		if(this.btn.classList.contains('disabled')) return
		
		this.getData()
		
		this.requestComplete = true
		if(this.data.startDate === '') { this.requestComplete = false;  }
		if(this.data.endDate === '') { this.requestComplete = false; }
		if(this.data.guestsTotal === 0 ) { this.requestComplete = false;  }
		if(this.data.quoteID === '') { this.requestComplete = false; }

		if(this.requestComplete === true) {
			this.form.submit()
		} else {
			// ERROR MESSAGE TO ADD?
			console.log('incomplete')
		}
	}
	updateBookBtn() {
		this.requestComplete = true
		if(this.data.startDate === '') { this.requestComplete = false;  }
		if(this.data.endDate === '') { this.requestComplete = false; }
		if(this.data.guestsTotal === 0 ) { this.requestComplete = false;  }
		if(this.data.quoteID === '' || this.data.quoteID == undefined) { this.requestComplete = false; }	
		if(!this.btn) return
		if(this.requestComplete === true) {
			this.btn.classList.remove('disabled')
		} else {
			this.btn.classList.add('disabled')
		}
	}

	init() {
		this.bindAll()
		this.getData()
		this.updateBookBtn()
		this.btn.addEventListener('click', this.bookNow)		
	}
}