function updateHeader(area) {
	let fixedArea = area.getBoundingClientRect().height
	document.documentElement.style.setProperty('--headerHeight', fixedArea + 'px')
}

function footerHeight() {
	const footer = document.querySelector('footer')
	let footerArea = footer.getBoundingClientRect().height
	document.documentElement.style.setProperty('--footerHeight', footerArea + 'px')
}

{
	const fixed = document.querySelector('.fixed')
	updateHeader(fixed)
	footerHeight()

	let resizing

	window.addEventListener('resize', () => {
		clearTimeout(resizing)
		resizing = setTimeout( resize, 100)
	})	

	function resize() {
		footerHeight()
		updateHeader(fixed)
		bookingDrawer.formHeight
	}
}