class WishlistAccount {
	constructor() {
		this.toggles = document.querySelectorAll('.wishlist_toggle')
		this.wishlistItems = (localStorage.getItem('wishlist')) ? JSON.parse(localStorage.getItem('wishlist')) : []
		this.loggedIn = false

		this.postAjaxUrl = '../../wp-content/themes/westay/ajax/wishlist.php'

		this.http = new XMLHttpRequest()

		this.init()
	}

	bindAll() {
		["toggleClickedEvent"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}
	
	toggleClickedEvent(e) {
		let el = e.srcElement,
			postID = el.dataset.id
		el.classList.toggle('active')
		this.updateActive(postID)
		if(this.loggedIn === false) {
			this.showAlert()
		}
	}

	showAlert() {
		let alert = document.querySelector('.log_register_alert')
		alert.classList.add('visible')
		document.body.classList.add('noscroll-alert')
		let close = alert.querySelector('.close')
		close.addEventListener('click', this.hideAlert)
	}
	hideAlert() {
		let alert = document.querySelector('.log_register_alert')
		alert.classList.remove('visible')
		document.body.classList.remove('noscroll-alert')
	}

	isLoggedIn() {
		let body = document.querySelector('body')
		if(body.classList.contains('logged-in')) {
			this.loggedIn = true
		} else {
			this.loggedIn = false
		}
	}

	update() {
		this.toggles = document.querySelectorAll('.wishlist_toggle')
		this.addEvents()
	}

	addEvents() {
		this.toggles.forEach( (toggle) => {
			toggle.addEventListener('click', this.toggleClickedEvent)
		})
		this.showActive()
	}

	updateActive(postID) {
		console.log(this.wishlistItems, this.wishlistItems.length)
		if(this.wishlistItems.length > 0) {
			if(this.wishlistItems[0] == '') {
				this.wishlistItems.splice(0, 1)
			}
			if(this.wishlistItems.includes(postID)) {
				this.wishlistItems = this.wishlistItems.filter(function (id) {
					return id !== postID
				})
			} else {
				this.wishlistItems.push(postID)
			}
		} else {
			this.wishlistItems.push(postID)
		}
		this.updateStorage()
		this.ajaxRequest('update')
	}

	updateStorage() {
		window.localStorage.setItem("wishlist", JSON.stringify(this.wishlistItems))
	}

	processItems(wlStr) {
		this.wishlistItems = wlStr.split(",")
		this.updateStorage()
		this.showActive()
	}

	wishlistStr() {
		this.getWishlistDatabase()
		return this.wishlistItems.toString()
	}

	ajaxRequest(method) {
		let wishlistItemsStr = this.wishlistItems.toString()
		this.method = method
		this.request = 'method=' + method
		this.request += '&wishlistItems=' + wishlistItemsStr
		if(this.loggedIn === true) {
			this.ajaxDatabase()
		}
	}

	getWishlistDatabase() {
		this.ajaxRequest('get')
		this.showActive()
	}

	ajaxDatabase() {
		let t = this
		this.http.onreadystatechange = function() {
			if(this.readyState == 4) {
				if(t.method === 'update') {
					window.localStorage.setItem("wishlistUpdatedAt", t.http.responseText)
				} else {
					if(t.method === 'get') {
						t.processItems(t.http.responseText)
					}
				}
			}
		}
		this.http.open('POST', this.postAjaxUrl, true)
		this.http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
		this.http.send(this.request)
	}

	showActive() {
		this.toggles.forEach( (toggle) => {
			let postID = toggle.dataset.id
			if(this.wishlistItems && this.wishlistItems.includes(postID)) {
				toggle.classList.add('active')
			}
		})
	}
	
	init() {
		this.bindAll()
		this.isLoggedIn()
		this.addEvents()
		this.getWishlistDatabase()
		this.updateStorage()
	}
}
const wishlist_save = new WishlistAccount()