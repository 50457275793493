{
	const form = document.querySelector('.booking_form_fixed')
	
	if(form) {
		let formHeight = form.getBoundingClientRect().height
		document.documentElement.style.setProperty('--bookingHeight', formHeight + 'px');
		
		fixedForm(form)
		window.addEventListener('scroll', () => { fixedForm(form); }, { passive: true} )
		window.addEventListener('resize', () => { fixedForm(form) }, { passive: true} )
	}
}

function fixedForm(form) {
	
	const scrollDistance = document.documentElement.scrollTop || document.body.scrollTop
	const windowHeight = window.innerHeight
	let adminBar = document.getElementById("wpadminbar") ? document.getElementById("wpadminbar").offsetHeight : 0
	
	let y = form.offsetTop,
		h = form.offsetHeight,
		vTop = y - windowHeight + h,
		vBottom = y + h
	if(vTop < scrollDistance ) {
		form.classList.remove('weFix')
	} else {
		form.classList.add('weFix')
	}
	
}

const propertiesGrid = document.querySelector('#propertiesGrid'),
	loadingSpinner = document.querySelector('#loading_spinner'),
	filterContainer = document.querySelector('.filter_container'),
	filterSearch = document.querySelector('#applyFilters')
	
let propertyDetails = ''

if(propertiesGrid) {
	propertyDetails = {
		'target' : propertiesGrid,
		'loadingSpinner' : loadingSpinner,
		'url' : '../../wp-content/themes/westay/ajax/properties.php',
		'filters': filterContainer,
		'filterBtn': filterSearch
	}
}

const properties = new AjaxPosts(propertyDetails)


function clearStorage() {
	window.localStorage.setItem("searchLocation", '')
	window.localStorage.setItem("searchLat", '')
	window.localStorage.setItem("searchLng", '')
	window.localStorage.setItem("startDateSelected", '')
	window.localStorage.setItem("endDateSelected", '')
	window.localStorage.setItem("filterChildren", 0)
	window.localStorage.setItem("filterAdults", 0)
	window.localStorage.setItem("filterPet", 0)
}

function clearFilters() {
	clearLocation()
	cal.clearSelection()
	clearGuests()
	properties.updateFilters()
}

const priceBreakdown = document.querySelector('.totalBreakdown')

const priceInfo = new BookingPrice(priceBreakdown)

function triggerGuestUpdate() {
	priceInfo.getInfo()
	bookingFormPost.getData()
}

const bookingButton = document.querySelector('#bookNow')
const bookingForm = document.querySelector('#bookingPost')
const bookingFormPost = new BookingPost(bookingButton, bookingForm)

let calendar_swiper = new Swiper(".calendar", calendarOptions)
const calendar = document.querySelector('.calendar')
const cal = new Calendar(calendar)

const resetIndicator = document.querySelector('.resetValues')
if(resetIndicator) {
	clearStorage()
}