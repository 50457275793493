{
	const share_toggles = document.querySelectorAll('.share_toggle')
	if(share_toggles) {
		share_toggles.forEach( (toggle) => {
			toggle.addEventListener('click', (e) => { shareToggle(toggle) }, { passive: false} )
		})
	}
}


function shareToggle(toggle) {
	toggle.classList.toggle('active')
	let options = toggle.parentElement.querySelector('.share_options')
	options.classList.toggle('visible')
}
