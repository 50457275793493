const customLightboxHTML = `<div id="glightbox-body" class="glightbox-container">
	<div class="gloader visible"></div>
	<div class="goverlay"></div>
	<div class="gcontainer">
		<div id="glightbox-slider" class="gslider"></div>
		<button class="gnext gbtn" tabindex="0" aria-label="Next" data-customattribute="example">{nextSVG}</button>
		<button class="gprev gbtn" tabindex="1" aria-label="Previous">{prevSVG}</button>
		<button class="gclose gbtn" tabindex="2" aria-label="Close">{closeSVG}</button>
		<button class="gplay gbtn" tabindex="3" aria-label="Play"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6zm-16.2 55.1l-352 208C45.6 483.9 32 476.6 32 464V47.9c0-16.3 16.4-18.4 24.1-13.8l352 208.1c10.5 6.2 10.5 21.4.1 27.6z"/></svg></button>
		<button class="gpause gbtn hidden" tabindex="-1" aria-label="Pause"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M48 479h96c26.5 0 48-21.5 48-48V79c0-26.5-21.5-48-48-48H48C21.5 31 0 52.5 0 79v352c0 26.5 21.5 48 48 48zM32 79c0-8.8 7.2-16 16-16h96c8.8 0 16 7.2 16 16v352c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V79zm272 400h96c26.5 0 48-21.5 48-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48zM288 79c0-8.8 7.2-16 16-16h96c8.8 0 16 7.2 16 16v352c0 8.8-7.2 16-16 16h-96c-8.8 0-16-7.2-16-16V79z"/></svg></button>
	</div>
	<div class="progress-bar"></div>
</div>`;


const lightbox = GLightbox({
	lightboxHTML: customLightboxHTML,
	touchNavigation: true,
	loop: true,
	width: "85vw",
	height: "80vh",
	closeOnOutsideClick: false,
});
let autoPlayInt;

lightbox.on('open', () => {
	const gplay = document.querySelector('.gplay'),
		gpause = document.querySelector('.gpause'),
		progress = document.querySelector('.progress-bar')
	
	gplay.addEventListener('click', (e) => {
		e.preventDefault()
		if(!autoPlayInt) {
			autoPlayInt = setInterval(() => {
				lightbox.nextSlide()
			}, 2500);
		}
		gplay.classList.add('hidden')
		gpause.classList.remove('hidden')
		progress.classList.remove('refresh')
		progress.classList.add('animate')
	}, { passive: false })

	gpause.addEventListener('click', (e) => {
		e.preventDefault()
		clearInterval(autoPlayInt)
		autoPlayInt = null
		gplay.classList.remove('hidden')
		gpause.classList.add('hidden')
		progress.classList.remove('animate')
		progress.classList.add('refresh')
	})
})

lightbox.on('close', () => {
	clearInterval(autoPlayInt)
	autoPlayInt = null
})

{
	const galleryTriggers = document.querySelectorAll('.gallery_trigger');
	galleryTriggers.forEach( (el) => {
		el.addEventListener('click', (e) => {
			e.preventDefault()
			lightbox.open()
		}, { passive: true })
	})
}