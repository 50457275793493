function navScroll() {
	let fixedHeader = document.querySelector('.fixed'),
		firstContent = document.querySelector('.section'),
		header = fixedHeader.querySelector('.header'),
		fixedHeaderHeight = fixedHeader.offsetHeight,
		fixedHeaderDistance = fixedHeaderHeight,
		hasImage = false	
	if(firstContent && firstContent.classList.contains('imageHeader')) {
		hasImage = true
		document.documentElement.style.setProperty('--headerHeight', '15px')
	}
	if(firstContent && firstContent.classList.contains('search_banner')) {
		fixedHeaderDistance = fixedHeaderHeight 
	}
	if(hasImage) {
		if(window.scrollY > fixedHeaderDistance) {
			header.classList.add('scrolled')
		} else {
			header.classList.remove('scrolled')
		}
	} else {
		header.classList.add('scrolled')
	}
}

window.addEventListener("load", navScroll, false)
window.addEventListener("scroll", navScroll, false)
window.addEventListener("resize", navScroll, false)