class Calendar {
	constructor(calendar) {
		if(!calendar) return
		this.calendar = calendar
		this.dateInput = calendar.querySelector('#dates')
		this.guestyDates = (this.dateInput.value) ? this.dateInput.value : ""
		if(this.guestyDates !== 'filter' && this.guestyDates !== '') {
			this.guestyDates = JSON.parse(this.dateInput.value);
		}
		this.minNights = (calendar.dataset.minNights) ? JSON.parse(calendar.dataset.minNights) : ""
		this.maxNights = (calendar.dataset.maxNights) ? JSON.parse(calendar.dataset.maxNights) : ""
		this.calendarBody = calendar.querySelector(".months")
		this.dateClear = calendar.querySelector('.clearSelection')
		this.dateClearIcon = document.querySelector('#clearDates')
		this.closeWindow = calendar.querySelector('.close')
		
		this.dateText = calendar.querySelector('.date_text')
		this.uiInfo = calendar.querySelector('.ui_info')

		this.animationSpeed = 100
		this.animationTransition = 200
		
		this.today = new Date()
		this.dayInt = this.today.getDate()
		this.month = this.today.getMonth()
		this.year = this.today.getFullYear()
		this.currentMonth = this.month
		this.currentYear = this.year
		
		this.months = [
			"Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
		]
		this.days = [
			'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'
		]

		this.availableStartDates = []
		this.availableBookingDates = []
		this.availableEndDates = []

		this.availableStartDatesEl = []
		this.availableBookingDatesEl = []
		this.availableEndDatesEl = []

		this.monthsToShow = 13
	
		this.prevBtn = this.calendar.querySelector('#prev')
		this.nextBtn = this.calendar.querySelector('#next')
		
		this.selectedStartDate =  ''
		this.selectedEndDate = ''
		this.selectedStartCell
		this.selectedEndCell
		this.init()
	}

	bindAll() {
		["prev", "next", "clearSelection", "clearIconBtn", "selectStartDate", "selectEndDate"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}

	loadStoredDates() { 

		const today = new Date()

		const startDateSelected = (window.localStorage.getItem('startDateSelected') != '' && window.localStorage.getItem('startDateSelected') != null) ? new Date(window.localStorage.getItem('startDateSelected')) : ''
		
		const endDateSelected = (window.localStorage.getItem('endDateSelected') != '' && window.localStorage.getItem('endDateSelected') != null) ? new Date(window.localStorage.getItem('endDateSelected')) : ''


		if(startDateSelected && startDateSelected > today) {
			this.selectedStartDate = startDateSelected
			let breakdown = this.getDateBreakdown(startDateSelected)
			let startDateEl = this.calendar.querySelector('li.singleDay[data-day="'+breakdown['day']+'"][data-month="'+breakdown['month']+'"][data-year="'+breakdown['year']+'"]');
			this.selectStartDate(startDateEl)
		} else {
			this.clearSelection()
			return
		}
		if(endDateSelected && endDateSelected > today) {
			this.selectedEndDate = endDateSelected
			let breakdown = this.getDateBreakdown(endDateSelected)
			let endDateEl = this.calendar.querySelector('li.singleDay[data-day="'+breakdown['day']+'"][data-month="'+breakdown['month']+'"][data-year="'+breakdown['year']+'"]');
			this.selectEndDate(endDateEl)
		} else {
			this.clearSelection()
			return
		}
	}

	getDateBreakdown(date) {
		let year = date.getFullYear(),
			month = date.getMonth(),
			day = date.getDate()
		
		return {
			year: year, 
			month: month, 
			day: day
		}
	}

	dayOfTheWeek(day, month, year) {
		const dayOfWeek = new Date(year, month, day).getDay()
		return isNaN(dayOfWeek) ? null : ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][dayOfWeek];
	}

	getLengthStay(start, end) {
		let length = end.getTime() - start.getTime(),
		lenInDays = length / (1000 * 60 * 60 * 24)
		lenInDays = Math.ceil(lenInDays)
		return lenInDays
	}

	showCalendar() {
		this.calendarBody.innerHTML = ""
		this.showMonths()
		calendar_swiper.updateSlides()
		this.loadStoredDates()
		this.updateDateText()
		this.findSelectedCells() 
		this.updateFilterText()		
	}

	findSelectedCells() {
		setTimeout( () => {
			let hasStartDate = document.querySelector('.postStart')
			if(hasStartDate) {
				this.selectStartDate(hasStartDate)
				hasStartDate.classList.remove('postStart')
			}
			let hasEndDate = document.querySelector('.postEnd')
			if(hasEndDate) {
				this.selectEndDate(hasEndDate)
				hasEndDate.classList.remove('postEnd')
			}
		}, 200)
	}


	updateFilterText() {
		const textField = document.querySelector('#datesSearch span')
		let infoText = 'Dates'
	
		if(this.selectedStartDate != '') {
			textField.parentElement.classList.add('hasValue')
			let startInfo = this.getDateBreakdown(this.selectedStartDate)
			infoText = startInfo['day'] + ' ' + this.months[startInfo['month']] + ' ' + startInfo['year']
		} else {
			textField.parentElement.classList.remove('hasValue')
		}
		if(this.selectedEndDate != '') {
			let endInfo = this.getDateBreakdown(this.selectedEndDate)
			infoText += ' - ' + endInfo['day'] + ' ' + this.months[endInfo['month']] + ' ' + endInfo['year']
		}
		textField.innerHTML = infoText
		if(this.guestyDates === 'filter') {
		}
	}

	updateDateText(text = 'Please select your holiday dates') {
		this.animateChange()
		setTimeout( () => {
			this.dateText.innerHTML = text
		}, this.animationSpeed)
	}

	updateUiInfo(text = 'Please select check-in date') {
		this.animateChange()
		setTimeout( () => {
			this.uiInfo.innerHTML = text
		}, this.animationSpeed)
	}

	animateChange() {
		this.calendar.classList.add('transition')
		setTimeout( () => {
			this.calendar.classList.remove('transition')
		}, this.animationTransition)
	}

	showMonths() {
		for(let x = 0; x < this.monthsToShow; x++) {
			let thisMonth = (this.month + x) % 12
			let thisYear = (this.month + x) > 11 ? this.year + 1 : this.year;

			let monthContainer = document.createElement('div')
			this.calendarBody.appendChild(monthContainer)
			monthContainer.classList.add('month', 'swiper-slide')
			monthContainer.classList.add(this.months[thisMonth] + '-' + thisYear) // MONTH CLASS
			
			// ADD MONTH NAME
			let monthName = document.createElement('h3')
			monthContainer.appendChild(monthName)
			monthName.innerHTML = this.months[thisMonth] + ' ' + thisYear
			
			// ADD DAY LABELS
			let labels = document.createElement('ul')
			monthContainer.appendChild(labels)
			labels.classList.add('weekdays')
			for(let i = 0; i < this.days.length; i++) {
				let li = document.createElement('li')
				labels.appendChild(li)
				li.innerHTML = this.days[i]
			}
			
			// ADD DAYS & BLANKS
			let days = document.createElement('ul')
			monthContainer.appendChild(days)
			days.classList.add('days')
			
			let firstDay = new Date(thisYear, thisMonth).getDay(),
			totalDays = this.daysInMonth(thisMonth, thisYear)
			
			this.blankDates(firstDay === 0 ? 6 : firstDay - 1, days)
			this.dates(totalDays, thisMonth, thisYear, days)
		}
		
		this.availableStartDatesEl = document.querySelectorAll('.bookable, .checkOutAvailable')
		this.availableStartDatesEl.forEach( (el) => {
			el.addEventListener('click', this.selectStartDate)
		})
	}
	
	prev() {
		calendar_swiper.slidePrev();
	}

	next() {
		calendar_swiper.slideNext();
	}

	blankDates(count, body) {
		for(let x = 0; x < count; x++) {
			let cell = document.createElement("li")
			let cellText = document.createTextNode("")
			cell.appendChild(cellText)
			cell.classList.add("empty")
			body.appendChild(cell)
		}
	}

	minNightsCalc(year, month, day, minNights) {
		let status = 'available'
		let start = minNights
		for(let x = 0; x < minNights; x++) {
			let date = new Date(year, month, (day + x)),
				dateInfo = this.getDateBreakdown(date)
			let thisDayNum = ('0' + (dateInfo['day'])).slice(-2)
			let thisMonthNum = ('0' + (dateInfo['month'] + 1)).slice(-2)
			let thisDate = dateInfo['year'] + '-' + thisMonthNum + '-' + thisDayNum

			let thisDayObj = (this.guestyDates !== 'filter') ? this.guestyDates[thisDate] : 'available'
			let thisDayStatus = (thisDayObj) ? thisDayObj.status : 'unavailable'
			let thisDayMin = (thisDayObj) ? thisDayObj.minNights : this.minNights

			if(thisDayMin > minNights) minNights = thisDayMin

			if(thisDayStatus != 'available') status = 'not_min'
		}
		return status
	}

	isCheckInDateCalc(year, month, day) {
		let status = 'booked'
		let date = new Date(year, month, day - 1),
			dateInfo = this.getDateBreakdown(date)
		let thisDayNum = ('0' + (dateInfo['day'])).slice(-2)
		let thisMonthNum = ('0' + (dateInfo['month'] + 1)).slice(-2)
		let thisDate = dateInfo['year'] + '-' + thisMonthNum + '-' + thisDayNum

		let thisDayObj = (this.guestyDates !== 'filter') ? this.guestyDates[thisDate] : 'available'
		let thisDayStatus = (thisDayObj) ? thisDayObj.status : 'unavailable'
		let thisDayMin = (thisDayObj) ? thisDayObj.minNights : this.minNights

		if(thisDayStatus === 'available') status = 'checkOutAvailable'
		
		return status
	}

	selectStartDate(el) {
		this.clearSelection()
		let startDate
		if(el.nodeName === 'LI') {
			let element = el
				startDate = el
		} else {
			let element = el['srcElement'],
				elementQuery = element.parentElement.nodeName
			startDate = element.parentElement
			if(elementQuery !== 'LI') {
				startDate = element
			}
		}
		let dayMinNights = startDate.dataset.dayMin
		this.availableStartDatesEl.forEach( (asd) => { asd.classList.remove('start_date')})

		let notificationLabels = document.querySelector('.notification_label')
		if(notificationLabels) notificationLabels.remove()

		let dayOfTheWeek = startDate.dataset.weekday

		if(startDate.classList.contains('unavailable')) { 
			return
		}
		if(startDate.classList.contains('checkInDisabled')) {
			let label = document.createElement('span'),
				labelText = document.createTextNode('Restrictions apply')
			label.classList.add('notification_label', dayOfTheWeek)
			startDate.appendChild(label)
			label.appendChild(labelText)
			startDate.classList.add('notification')
			return
		}

		if(!startDate.classList.contains('checkInAvailable')) {
			let label = document.createElement('span'),
				labelText = document.createTextNode(dayMinNights + '-night minimum')
			label.classList.add('notification_label', dayOfTheWeek)
			startDate.appendChild(label)
			label.appendChild(labelText)
			startDate.classList.add('notification')
			return
		}

		let day = Number(startDate.dataset.day),
			month = Number(startDate.dataset.month),
			year = Number(startDate.dataset.year)

		startDate.classList.add('start_date')
		this.selectedStartDate = new Date(year, month, day)
		this.selectedStartCell = startDate
		this.calendar.classList.add('startDateSelected')
		this.limitEndDate(year, month, day, dayMinNights)
		this.updateEndDates()
		this.dateClear.classList.add('visible')

		if(this.guestyDates === 'filter') {
			this.updateDateText('Please select checkout date')
		} else {
			this.updateDateText('Minimum stay: ' + dayMinNights + ' nights')
		}

		this.updateUiInfo('Please select checkout date')
		this.updateFilterText()

		this.availableStartDatesEl.forEach( (el) => {
			el.removeEventListener('click', this.selectStartDate)
		})

		window.localStorage.setItem("startDateSelected", this.selectedStartDate)
	}

	selectEndDate(el) {
		let endDate
		if(el.nodeName === 'LI') {
			let element = el
			endDate = el
		} else {
			let element = el['srcElement'],
				elementQuery = element.parentElement.nodeName

			endDate = element.parentElement

			if(elementQuery !== 'LI') {
				endDate = element
			}
		}
		if(!this.selectedStartDate) return
		
		let dayMinNights = endDate.dataset.dayMin
		let dayOfTheWeek = endDate.dataset.weekday

		let notificationLabels = document.querySelector('.notification_label')
		if(notificationLabels) {
			notificationLabels.remove()
			let notClass = document.querySelector('.notification')
			notClass.classList.remove('notification')
		}

		if(endDate.classList.contains('checkOutDisabled')) {
			let label = document.createElement('span'),
				labelText = document.createTextNode('Restrictions apply')
			label.classList.add('notification_label', dayOfTheWeek)
			endDate.appendChild(label)
			label.appendChild(labelText)
			endDate.classList.add('notification')
			return
		}

		if(!endDate.classList.contains('selectCheckOut')) {
			let label = document.createElement('span'),
				labelText = document.createTextNode(dayMinNights + '-night minimum')
			label.classList.add('notification_label', dayOfTheWeek)
			endDate.appendChild(label)
			label.appendChild(labelText)
			endDate.classList.add('notification')
			return
		}
		this.availableEndDatesEl.forEach( (elx) => { elx.classList.remove('end_date')})

		let day = Number(endDate.dataset.day),
			month = Number(endDate.dataset.month),
			year = Number(endDate.dataset.year)

		endDate.classList.add('end_date')
		this.selectedEndDate = new Date(year, month, day)
		this.selectedEndCell = endDate
		this.addJoin()

		this.availableEndDatesEl.forEach( (el) => {
			el.removeEventListener('click', this.selectEndDate)
		})
		this.availableStartDatesEl.forEach( (el) => {
			el.addEventListener('click', this.selectStartDate)
		})
		this.calendar.classList.add('endDateSelected')
		let disabledDates = document.querySelectorAll('.selectDisable')
		disabledDates.forEach( (el) => {
			el.classList.remove('selectDisable')
		})
		window.localStorage.setItem("endDateSelected", this.selectedEndDate)
		this.setStayDetails()
	}

	setStayDetails() {
		let startInfo = this.getDateBreakdown(this.selectedStartDate),
			endInfo = this.getDateBreakdown(this.selectedEndDate),
			stayLength = this.getLengthStay(this.selectedStartDate, this.selectedEndDate)
	
		let infoText = startInfo['day'] + ' ' + this.months[startInfo['month']] + ' ' + startInfo['year']
			infoText += ' - ' + endInfo['day'] + ' ' + this.months[endInfo['month']] + ' ' + endInfo['year']
		let uiInfo = 'Length of stay: ' + stayLength + ' nights'
		this.updateDateText(uiInfo)
		this.updateUiInfo(infoText)
		this.updateFilterText()
		triggerGuestUpdate()
	}

	addJoin() {
		let startDateObj = this.getDateBreakdown(this.selectedStartDate),
			lenInDays = this.getLengthStay(this.selectedStartDate, this.selectedEndDate)
		
		let bookingDays = []

		for(let x = 0; x <= lenInDays; x++) {
			let date = new Date(startDateObj['year'], startDateObj['month'], startDateObj['day'] + x),
				thisYear = date.getFullYear(),
				thisMonth = date.getMonth(),
				thisDay = date.getDate()

			let thisDayNum = ('0' + (thisDay)).slice(-2)
			let thisMonthNum = ('0' + (thisMonth + 1)).slice(-2)
			let thisDate = thisYear + '-' + thisMonthNum + '-' + thisDayNum
			bookingDays.push(thisDate)
		}

		let dates = document.querySelectorAll('.bookable')
		dates.forEach( (date) => {
			date.classList.remove('bookingLength')
			let day = Number(date.dataset.day),
				month = Number(date.dataset.month),
				year = Number(date.dataset.year)

			let thisDayNum = ('0' + (day)).slice(-2)
			let thisMonthNum = ('0' + (month + 1)).slice(-2)
			let thisDate = year + '-' + thisMonthNum + '-' + thisDayNum
			
			if(bookingDays.includes(thisDate)) date.classList.add('bookingLength')
		})
		
	}

	limitEndDate(year, month, day, minNights) {
		this.availableEndDates = []
		this.availableBookingDates = []
		for(let x = 0; x <= this.maxNights; x++) {
			let date = new Date(year, month, day + x),
				thisYear = date.getFullYear(),
				thisMonth = date.getMonth(),
				thisDay = date.getDate()

			let thisDayNum = ('0' + (thisDay)).slice(-2)
			let thisMonthNum = ('0' + (thisMonth + 1)).slice(-2)
			let thisDate = thisYear + '-' + thisMonthNum + '-' + thisDayNum
			
			let thisDayObj = (this.guestyDates !== 'filter') ? this.guestyDates[thisDate] : 'available'
			let thisDayStatus = (thisDayObj) ? thisDayObj.status : 'unavailable'
			let thisCTD = (thisDayObj) ? thisDayObj.ctd : false
			
			if(this.guestyDates === 'filter') {
				thisDayStatus = 'available'
				thisCTD = false
			}

			if(x >= minNights && thisCTD === false) this.availableEndDates.push(thisDate)
			this.availableBookingDates.push(thisDate)

			if(thisDayStatus != 'available') return
		}
	}

	updateEndDates() {
		let dates = document.querySelectorAll('.singleDay')
		dates.forEach( (date) => {
			date.classList.remove('selectCheckOut', 'selectDisable', 'bookable')
			let day = Number(date.dataset.day),
				month = Number(date.dataset.month),
				year = Number(date.dataset.year)

			let thisDayNum = ('0' + (day)).slice(-2)
			let thisMonthNum = ('0' + (month + 1)).slice(-2)
			let thisDate = year + '-' + thisMonthNum + '-' + thisDayNum

			
			if(this.availableBookingDates.includes(thisDate)) date.classList.add('bookable')
			if(this.availableEndDates.includes(thisDate)) date.classList.add('selectCheckOut')
			if(!date.classList.contains('bookable')) date.classList.add('selectDisable')	
		})
		this.availableBookingDatesEl = document.querySelectorAll('.bookable')
		this.availableEndDatesEl = document.querySelectorAll('.bookable')
		this.availableEndDatesEl.forEach( (el) => {
			el.addEventListener('click', this.selectEndDate)
		})
	}

	dates(totalDays, month, year, body) {
		
		for(let x = 1; x <= totalDays; x++) {
			let classes = [];
			let cell = document.createElement("li")
			let cellText = document.createTextNode(x)
			if(this.dayInt > x && this.month === month && this.year === year ) {
				classes.push('disabled')
			}
			if(this.dayInt === x && this.month === month && this.year === year ) {
				classes.push('today', 'disabled')
			}
			let thisDayNum = ('0' + x).slice(-2)
			let thisMonth = ('0' + (month + 1)).slice(-2)
			let thisDate = year + '-' + thisMonth + '-' + thisDayNum
			let thisDayObj = (this.guestyDates !== 'filter') ? this.guestyDates[thisDate] : 'available'
			let thisDayStatus = (thisDayObj) ? thisDayObj.status : 'unavailable'
			let thisDayMin = (thisDayObj) ? thisDayObj.minNights : this.minNights
			let thisCTA = (thisDayObj) ? thisDayObj.cta : false
			let thisCTD = (thisDayObj) ? thisDayObj.ctd : false
			let dayOfWeek = this.dayOfTheWeek(x, month, year);

			let checkOutAvailable = this.isCheckInDateCalc(year, month, x)
			let meetsMinStay = this.minNightsCalc(year, month, x, thisDayMin)

			if(this.guestyDates === 'filter') {
				thisDayStatus = 'available'
				thisDayMin = 1
				thisCTA = false
				thisCTD = false
				meetsMinStay = 'available'
				checkOutAvailable = 'checkOutAvailable'
			}

			classes.push('singleDay')
			switch(thisDayStatus) {
				case 'booked':
					if(classes.includes('disabled')) break
					classes.push('booked')
					if(checkOutAvailable === 'checkOutAvailable' && thisCTD === false) classes.push('checkOutAvailable') // SHOW AVAILABLE CHECKOUT
					break
				case 'available':
					if(classes.includes('disabled')) break
					if(classes.includes('today')) break // PREVENT BOOKINGS TODAY
					if(meetsMinStay == 'available' && thisCTA === false) classes.push('checkInAvailable')
					classes.push('bookable')
					if(checkOutAvailable === 'checkOutAvailable' && thisCTD === false) classes.push('checkOutAvailable') // SHOW AVAILABLE CHECKOUT
					break
				case 'unavailable':
					if(classes.includes('disabled')) break // PREVENT DOUBLE NEGATIVE CLASSES
					classes.push('unavailable')
					if(checkOutAvailable === 'checkOutAvailable' && thisCTD === false) classes.push('checkOutAvailable') // SHOW AVAILABLE CHECKOUT
					break
				default:
					if(classes.includes('disabled')) break // PREVENT DOUBLE NEGATIVE CLASSES
					classes.push('unavailable')
					break
			}
			if(thisCTA !== false) classes.push('checkInDisabled')
			if(thisCTD !== false) classes.push('checkOutDisabled')

			if(this.selectedStartDate) {				
				let startDate = this.getDateBreakdown(this.selectedStartDate)
				if(startDate.year === year && startDate.month === month && startDate.day === x) {
					classes.push('postStart')
				}
			}
			if(this.selectedEndDate) {
				let endDate = this.getDateBreakdown(this.selectedEndDate)
				if(endDate.year === year && endDate.month === month && endDate.day === x) {
					classes.push('postEnd')
				}
			}

			cell.classList.add(...classes)
			cell.setAttribute("data-day", x)
			cell.setAttribute("data-month", month)
			cell.setAttribute("data-year", year)
			cell.setAttribute("data-day-min", thisDayMin)
			cell.setAttribute("data-weekday", dayOfWeek)
			let cellTextSpan  = document.createElement('span')
			cell.appendChild(cellTextSpan)
			cellTextSpan.appendChild(cellText)
			body.appendChild(cell)

		}
	}

	daysInMonth(month, year) {
		return new Date(year, month + 1, 0).getDate()
	}

	clearSelection() {
		
		this.selectedStartDate = ''
		this.selectedEndDate = ''
		this.calendar.classList.remove('startDateSelected', 'endDateSelected')
		this.dateClear.classList.remove('visible')
		this.updateDateText()
		this.updateUiInfo()
		this.updateFilterText()
		this.availableEndDatesEl = document.querySelectorAll('.selectCheckOut')
		this.availableEndDatesEl.forEach( (el) => {
			el.removeEventListener('click', this.selectEndDate)
		})
		let dates = document.querySelectorAll('.singleDay')
		dates.forEach( (date) => { 
			date.classList.remove('selectCheckOut', 'selectDisable', 'bookable', 'start_date', 'end_date', 'bookingLength', 'notification')
		})
		this.availableStartDatesEl.forEach( (el) => {
			el.addEventListener('click', this.selectStartDate)
		})
		window.localStorage.setItem("startDateSelected", this.selectedStartDate)
		window.localStorage.setItem("endDateSelected", this.selectedEndDate)
		if (typeof triggerGuestUpdate === 'function') {
			triggerGuestUpdate()
		}
		//if(bookingFormPost) bookingFormPost.getData()
	}

	clearIconBtn(e) {
		e.stopPropagation()
		this.clearSelection()
	}

	init() {
		this.bindAll()
		this.showCalendar()

		this.prevBtn.addEventListener('click', this.prev)
		this.nextBtn.addEventListener('click', this.next)
		this.dateClear.addEventListener('click', this.clearSelection)
		if(this.dateClearIcon) this.dateClearIcon.addEventListener('click', this.clearIconBtn, false)

	}
}
