function guestInputInit() {
	document.addEventListener("touchstart", function() {

	}, false)
	setValues()
	setInputButtonState()
	
	const guestClearBtn = document.querySelector('#clearGuests')
	if(guestClearBtn) {
		guestClearBtn.addEventListener('click', (e) => {
			e.stopPropagation()
			clearGuests()
			
		}, false)
	}
}

function setValues() {

	const adultsEl = document.querySelector('#adults'),
		childEl = document.querySelector('#children'),
		petsEl = document.querySelector('#pet'),
		adultsMax = adultsEl.getAttribute('max'),
		petsMax = Number(petsEl.getAttribute('max'))

	let adults = (window.localStorage.getItem("filterAdults") != null) ? Number(window.localStorage.getItem("filterAdults"))  : 0,
		kids = (window.localStorage.getItem("filterChildren") != null) ? Number(window.localStorage.getItem("filterChildren")) : 0,
		pets = (window.localStorage.getItem("filterPet") != null) ? Number(window.localStorage.getItem("filterPet")) : 0

	if(pets > petsMax) {
		pets = 0
		window.localStorage.setItem("filterPet", 0)
	}

	if((adults + kids) > adultsMax) {
		adults = (pets > 0) ? 1 : 0
		kids = 0
		window.localStorage.setItem("filterChildren", kids)
		window.localStorage.setItem("filterAdults", adults)
	}

	if(pets > 0) setInputValue(petsEl, pets)
	if(adults > 0) setInputValue(adultsEl, adults)
	if(kids > 0) setInputValue(childEl, kids)
	setInputButtonState()
	updateMaxGuests()
}

function clearGuests() {
	const adultsEl = document.querySelector('#adults'),
		childrenEl = document.querySelector('#children'),
		petEl = document.querySelector('#pet')
	
	if(childrenEl.value > 0) setInputValue(childrenEl, 0)
	if(petEl.value > 0) setInputValue(petEl, 0)
	if(adultsEl.value > 0) setInputValue(adultsEl, 0) // DONE LAST

	window.localStorage.setItem("filterChildren", 0)
	window.localStorage.setItem("filterPet", 0)
	window.localStorage.setItem("filterAdults", 0)

	updateGuestText()
	setInputButtonState()

	if(bookingFormPost) bookingFormPost.getData()
}

function updateGuestText() {
	minGuestValidation()

	const label = document.querySelector('#guestSearch span')
	
	let adults = (window.localStorage.getItem("filterAdults") != null) ? Number(window.localStorage.getItem("filterAdults"))  : 0,
		kids = (window.localStorage.getItem("filterChildren") != null) ? Number(window.localStorage.getItem("filterChildren")) : 0,
		pets = (window.localStorage.getItem("filterPet") != null) ? Number(window.localStorage.getItem("filterPet")) : 0

	let adultPlural = (adults > 1) ? ' Adults' : ' Adult',
		kidPlural = (kids > 1) ? ' Children' : ' Child',
		petsPlural = (pets > 1) ? ' Pets' : ' Pet',
		labelText = adults + adultPlural
		if(kids > 0) labelText += ', ' + kids + kidPlural
		if(pets > 0) labelText += ', ' + pets + petsPlural
		if(adults === 0 && kids === 0 && pets === 0) {
			labelText = 'Guests'
		}
		
	label.innerHTML = labelText
	const guestClear = document.querySelector('#clearGuests').parentElement
	if(adults > 0) {
		guestClear.classList.add('hasValue')
	} else {
		guestClear.classList.remove('hasValue')
	}
}

function minGuestValidation() {
	const adultsEl = document.querySelector('#adults')
	let adults = (window.localStorage.getItem("filterAdults") != null) ? Number(window.localStorage.getItem("filterAdults"))  : '',
		kids = (window.localStorage.getItem("filterChildren") != null) ? Number(window.localStorage.getItem("filterChildren")) : '',
		pets = (window.localStorage.getItem("filterPet") != null) ? Number(window.localStorage.getItem("filterPet")) : ''

	if(kids > 0 || pets > 0) {
		adultsEl.setAttribute('min', 1)
		setInputButtonState()
		if(adults === 0) setInputValue(adultsEl, 1)
	}
	if(kids === 0 && pets === 0) {
		adultsEl.setAttribute('min', 0)
		setInputButtonState()
	}

}

function handleNumberInput() {
	setInputButtonState();
	updateGuestText()
}

function handleNumberInputBlur(event) {
	const value = event.target.value;

	if(event.target.hasAttribute("min") && value < parseFloat(event.target.min)) event.target.value = event.target.min

	if(event.target.hasAttribute("max") && value > parseFloat(event.target.max)) event.target.value = event.target.max
}

function setInputButtonState() {
	const inputs = document.querySelectorAll('.number-input-text-box')

	for(let input of inputs) {
		if(input.id.length > 0) {
			const value = input.value
			const parent = input.parentElement.parentElement

			if(parent.children[0] && input.hasAttribute("min")) parent.children[0].disabled = value <= parseFloat(input.min)

			if(parent.children[2] && input.hasAttribute("max")) parent.children[2].disabled = value >= parseFloat(input.max)
		}
	}
}

function setNumber(event) {
	let button = event.target
	let input = document.getElementById(button.dataset.inputId)

	if(input) {
		let value = parseFloat(input.value)
		let step = parseFloat(input.dataset.step)

		if(button.dataset.operation === 'decrement') {
			value -= isNaN(step) ? 1 : step
		} else if(button.dataset.operation === 'increment') {
			value += isNaN(step) ? 1 : step
		}

		if(input.hasAttribute('min') && value < parseFloat(input.min)) value = input.min
		if(input.hasAttribute('max') && value > parseFloat(input.max)) value = input.max

		if(input.value !== value) {
			setInputValue(input, value)
			setInputButtonState()
		}
	}
	updateGuestText()
	updateMaxGuests()
	triggerGuestUpdate()
	
}

function updateMaxGuests() {
	const adultsEl = document.querySelector('#adults'),
		kidsEl = document.querySelector('#children')

	let adults = (window.localStorage.getItem("filterAdults") != null) ? Number(window.localStorage.getItem("filterAdults"))  : 0,
		kids = (window.localStorage.getItem("filterChildren") != null) ? Number(window.localStorage.getItem("filterChildren")) : 0,
		allowedTotal = Number(document.querySelector('.guestSelector').dataset.guestMax)

	let allowedAdults = (allowedTotal - kids),
		allowedKids = (allowedTotal - adults)

	if(allowedKids < 0) {
		window.localStorage.setItem("filterChildren", 0)
		setInputValue(kidsEl, 0)
	}
	if(allowedAdults < 0) {
		window.localStorage.setItem("filterPet", 0)
		window.localStorage.setItem("filterAdults", 0)
		setInputValue(adultsEl, 0)
	}
	

	adultsEl.setAttribute('max', allowedAdults )
	kidsEl.setAttribute('max', allowedKids )
	setInputButtonState()
}

function setInputValue(input, value) {
	let newInput = input.cloneNode(true)
	const parentBox = input.parentElement.getBoundingClientRect()
	
	let string = input.name,
		inputID = 'filter' + string[0].toUpperCase() + string.slice(1)
	

	input.id = ""

	newInput.value = value

	if(value > input.value) {
		input.parentElement.appendChild(newInput)
		input.style.marginLeft = -parentBox.width + 'px'
	} else if(value < input.value) {
		newInput.style.marginLeft = -parentBox.width + 'px'
		input.parentElement.prepend(newInput)
		window.setTimeout(() => {
			newInput.style.marginLeft = 0
		}, 20)
	}

	window.setTimeout( () => {
		input.parentElement.removeChild(input)
	}, 250)

	window.localStorage.setItem(inputID, value)
}

{

	const guestInputs = document.querySelectorAll('.number-input-text-box')
	if(guestInputs.length > 0) {
		guestInputInit()
		updateGuestText() 
	}

	const filters = document.querySelectorAll('.toggle')
	if(filters.length > 0) {
		window.addEventListener('load', toggleSelector, false);
		const clearFilterBtn = document.querySelector('#clearFilters')
		if(clearFilterBtn) clearFilterBtn.addEventListener('click', clearFilters)
	}

}

function toggleSelector() {

	const filters = document.querySelectorAll('.hideReveal')
	const terms = document.querySelector('.total_terms')
	
	filters.forEach( (el) => {

		let btn = el.querySelector('.toggle'),
			selector = el.querySelector('.selector'),
			closeToggle = el.querySelector('.close')

		selector.classList.add('js-active')

		btn.addEventListener('click', (e) => {
			if(btn.classList.contains('active')) {
				btn.classList.remove('active')
				selector.style.maxHeight = null
				selector.classList.remove('active')
				btn.closest('.hideReveal').classList.remove('active')
				return
			}
			if(terms) {
				if(btn.getAttribute('id') === 'breakdownView' ) {
					terms.classList.add('top')
				} else {
					terms.classList.remove('top')
				}
			}
			
			closeAllSelectors()
			
			btn.classList.add('active')
			btn.closest('.hideReveal').classList.add('active')
			selector.style.maxHeight = selector.scrollHeight + 'px'
			selector.classList.add('active')
		}, false)

		if(closeToggle) {
			closeToggle.addEventListener('click', (e) => {
				closeAllSelectors()
			})
		}
	})
}

function closeAllSelectors() {
	let active = document.querySelector('.toggle.active')
	if(active) {
		active.classList.remove('active')
		active.closest('.hideReveal').classList.remove('active')
		let sel = active.parentElement.querySelector('.selector')
		sel.style.maxHeight = null
		sel.classList.remove('active')
	}
}