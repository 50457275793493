class AjaxPosts {
	constructor(details) {
		if(!details) return
		this.request = 'paged=1'

		this.postTarget = details.target
		this.postLoadingSpinner = details.loadingSpinner
		this.postAjaxUrl = details.url

		this.amenitiesFilter = this.postTarget.dataset.amenities
		this.listingType = this.postTarget.dataset.listingType
		this.wishlist = this.postTarget.dataset.wishlist
		this.properties = this.postTarget.dataset.properties

		this.page = 1
		
		this.filters = details.filters
		if(this.filters) {
			this.filterBtn = details.filterBtn
			this.amenities = []
			this.getLocation()
			this.getDates()
			this.getGuests()
			this.checkboxFilters = document.querySelectorAll('.filterCheckbox input')
			this.getFilterCheckboxes()
		}

		
		this.http = new XMLHttpRequest()

		this.init()
	}

	bindAll() {
		["updateFilters"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}
	
	getLocation() {
		this.filterSearchValue = (window.localStorage.getItem("searchLocation") != null) ? window.localStorage.getItem("searchLocation") : ''
		this.filterSearchLatValue = (window.localStorage.getItem("searchLat") != null) ? window.localStorage.getItem("searchLat") : ''
		this.filterSearchLngValue = (window.localStorage.getItem("searchLng") != null) ? window.localStorage.getItem("searchLng") : ''
		if(this.filterSearchValue === '') {
			this.filterSearchLatValue = ''
			this.filterSearchLngValue = ''
		} 
	}

	getGuests() {
		this.filterGuestAdultsInt = (window.localStorage.getItem("filterAdults") != null) ? Number(window.localStorage.getItem("filterAdults")) : ''
		this.filterGuestChildrenInt = (window.localStorage.getItem("filterChildren") != null) ? Number(window.localStorage.getItem("filterChildren")) : ''
		this.filterGuestPetsInt = (window.localStorage.getItem("filterPet") != null) ? Number(window.localStorage.getItem("filterPet")) : ''
		this.guestTotal = this.filterGuestAdultsInt + this.filterGuestChildrenInt
	}

	getFilterCheckboxes() {
		this.amenities = []
		if(this.amenitiesFilter != '') this.amenities.push(this.amenitiesFilter)
		this.checkboxFilters.forEach( (el) => {
			let split = el.dataset.amenity.split(", ")
			split.forEach((id) => {
				if(id === this.amenitiesFilter) {
					el.checked = true
				} else {
					if(el.checked) {
						this.amenities.push(id)
					}
				}
			})
		})
	}

	getDateBreakdown(date) {
		let year = date.getFullYear(),
			month = ('0' + (date.getMonth() + 1)).slice(-2),
			day = ('0' + date.getDate()).slice(-2)
		
		return year + '-' + month + '-' + day 
	}


	getDates() {
		this.filterStartDateValue = (window.localStorage.getItem(('startDateSelected'))) ? this.getDateBreakdown(new Date(window.localStorage.getItem(('startDateSelected')))) : ''
		this.filterEndDateValue = (window.localStorage.getItem(('endDateSelected'))) ? this.getDateBreakdown(new Date(window.localStorage.getItem(('endDateSelected')))) : ''
	}

	displayPosts() {
		
		this.postTarget.classList.add('transition')
		this.postLoadingSpinner.classList.add('loading')
		this.postTarget.parentElement.classList.add('transition')
		
		let t = this
		this.http.onreadystatechange = function() {
			if(this.readyState == 4) {

				if(t.http.responseText === '') {
					t.postTarget.parentElement.classList.add('no_results')
				} else {
					t.postTarget.parentElement.classList.remove('no_results')
				}

				t.postTarget.innerHTML = t.http.responseText
				setTimeout(function() {
					t.postLoadingSpinner.classList.remove('loading')
					t.postTarget.classList.remove('transition')
					t.postTarget.parentElement.classList.remove('transition')
					t.postTarget.style = 'min-height: 0px'
				}, 100)
				setTimeout(function() {
					let height = t.postTarget.offsetHeight
					t.postTarget.style = 'min-height: ' + height + 'px'
					const pageLinks = document.querySelectorAll('.page_link span')
					pageLinks.forEach( (el) => {
						el.addEventListener('click', (e) => {
							let pageNo = el.parentElement.dataset.page
							t.updatePage(pageNo)
						})
					})
					wishlist_save.update()
				}, 500)
			}
		}
		this.http.open('POST', this.postAjaxUrl, true)
		this.http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
		this.http.send(this.request)
		
	}

	getWishlistProps() {
		const wishlistItems = JSON.parse(localStorage.getItem('wishlist'))
		this.wishListAdvice(wishlistItems)
		this.wishlistProperties = wishlist_save.wishlistStr()
	}

	wishListAdvice(wishlistItems) {
		let adviceSection = document.querySelector('.wishlist_advice')
		if(wishlistItems.length < 1) {
			adviceSection.classList.add('visible')
		} else {
			adviceSection.classList.remove('visible')
		}
	}

	updatePosts(scroll = true) {
		this.request = 'paged=' + this.page
		if(this.guestTotal != 0) this.request += '&totalGuests=' + this.guestTotal
		if(this.filterGuestPetsInt != 0) this.request += '&pet=' + this.filterGuestPetsInt
		if(this.filterStartDateValue != '') this.request += '&startDateInput=' + this.filterStartDateValue
		if(this.filterEndDateValue != '') this.request += '&endDateInput=' + this.filterEndDateValue
		if(this.filterSearchLatValue != '' && this.filterSearchLngValue != '') this.request += '&searchLat=' + this.filterSearchLatValue + '&searchLng=' + this.filterSearchLngValue
		if(this.amenities != '') this.request += '&amenitiesFilter=' + this.amenities.toString()
		if(this.listingType != '') this.request += '&listingType=' + this.listingType
		if(this.properties != '') this.request += '&properties=' + this.properties
		if(this.wishlist != '') {
			this.getWishlistProps()
			this.request += '&wishlist=' + this.wishlistProperties
		}

		this.displayPosts()
		if(scroll) {
			setTimeout( () => {
				scrollToHash('#' + this.postTarget.id)
			}, 500)
		}
	}

	updateFilters(scroll = true) {
		closeAllSelectors()
		this.getLocation()
		this.getDates()
		this.getGuests()
		this.getFilterCheckboxes()
		this.page = 1
		this.updatePosts(scroll)
	}

	updatePage(newPage) {
		this.page = newPage
		this.updatePosts()
	}

	updateSearch(searchTerm) {
		this.page = 1
		this.searchTerm = searchTerm
		this.updatePosts()
	}
	
	init() {
		this.bindAll()
		this.updateFilters(false)

		if(this.filterBtn) {
			this.filterBtn.addEventListener('click', this.updateFilters)
		}

	}
}
