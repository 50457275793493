class BookingCheckout {
	constructor(container) {
		this.container = container
		if(!this.container) return

		this.steps = this.container.querySelectorAll('.step')
		this.activeStep = this.container.querySelector('.step.active')

		this.btns = this.container.querySelectorAll('.continueBtn')
		this.fields = this.container.querySelectorAll('.booking_field')

		this.isValid = false

		this.data = {}
		this.passedData = {}

		this.init()
	}

	bindAll() {
		["fieldValidation", "fieldEvent", "changeActiveEvent"].forEach((fn) => (this[fn] = this[fn].bind(this)))
	}

	fieldEvent(e) {
		const el = e.srcElement
		this.fieldValidation(el)
	}

	setAmount(amount) {
		this.total = amount
	}

	fieldValidation(el) {
		const type = el.type,
			value = el.value,
			parent = el.closest(".booking_field"),
			errorDisplay = parent.querySelector('.error'),
			required = el.required
			

		let isValid = false,
			errorMessage = ' '

		el.classList.remove('invalid')
		el.classList.remove('valid')

		if(required === false) return

		if(value == '') {
			isValid = false
			errorMessage = 'This field is required'
		} else {
			switch(type) {
				case 'text' :
					const label = el.parentElement.querySelector('label').innerText
					isValid = (value.length >= 2) ? true : false
					errorMessage = label + ' needs to be at least 2 characters'
					break
				case 'email' :
					const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					isValid = emailRegex.test(String(value).toLowerCase())
					errorMessage = 'Please supply a valid email address'
					break
				case 'tel' :
					const telRe = /^(\+[1-9]{1}[0-9]{3,14})?([0-9]{9,14})$/
					let telVal = value.replace(/\s+/g, '')
						telVal = telVal.replace('+', '')
					isValid = telRe.test(telVal)
					errorMessage = 'Please supply a valid phone number'
					break
				case 'checkbox' :
					let checked = el.checked
					isValid = (checked) ? true : false
					errorMessage = 'You need to agree to continue'
					break
				default : 
					isValid = true
					break
			}
		}

		if(isValid) {
			errorDisplay.innerText = ''
			errorDisplay.classList.add('valid')
			el.classList.add('valid')
			el.classList.remove('invalid')
		} else {
			errorDisplay.classList.remove('valid')
			errorDisplay.innerText = errorMessage
			el.classList.add('invalid')
			el.classList.remove('valid')
		}
		this.isValid = isValid
	} 

	addPhoneCountry() {
		const input = this.container.querySelector("input[type='tel'].validation");
		this.telField = intlTelInput(input, {
			initialCountry: "gb",
			preferredCountries: ["gb", "es"],
			utilsScript: "/wp-content/themes/westay/js/utils.js",
		});
	}

	changeActiveEvent(e) {
		e.preventDefault()
		const el = e.srcElement
		this.changeActive(el)
	}

	changeActive(el) {
		const target = el.dataset.step,
			direction = el.dataset.direction
		let stepValid = true

		if(direction === 'next') {
			this.fields = this.activeStep.querySelectorAll('.booking_field')
			this.fields.forEach( (field) => {
				let input = field.querySelector('input')
				this.fieldValidation(input)
				if(this.isValid == false) stepValid = false
			})
			if(target == 'step_three') this.guestyPaySetup()
		}
		//stepValid = true //BYPASS VERIFICATION
		if(stepValid === false) return

		this.activeStep.classList.remove('active')
		this.container.querySelector('#' + target).classList.add('active')
	
		let isActive = false
		this.steps.forEach( (step) => {
			if(step.classList.contains("active")) isActive = true 

			if(isActive === true) step.classList.remove('complete')
		
		})

		if(direction === 'next') this.activeStep.classList.add('complete')
		
		this.activeStep = this.container.querySelector('.step.active')

	}

	addData(label, value) {
		this.passedData[label] = value
	}

	guestyPaySetup() {
		
		let data = []
		let inputs = this.container.querySelectorAll('.booking_field input.validation')
		inputs.forEach( (input) => {
			let type = input.type,
				value = (type == 'checkbox') ? input.checked : input.value,
				name = input.id
			data[name] = value
		})

		data['amount'] = this.total
		this.data = data
		
		setTimeout( () => {
			guestyPay(data)
		}, 100)
	}

	guestyPaySuccess(token, btn, errorMessage) {
		if(!token) {
			rejectedPayment(token, btn, errorMessage)
			return
		}
		this.data['privacy'] = this.data['terms']
		this.data['ccToken'] = token['_id']
		this.data['phone'] = this.telField.getNumber()
		this.merged = { ...this.data, ...this.passedData}
		this.guestySendBooking()
	}

	guestySendBooking() {
		let form = document.querySelector('#bookingPost')
		Object.entries(this.merged).forEach( ([label, value]) => {
			let field = form.querySelector("#" + label + 'Submit')
			field.value = value
		})

		form.submit()
	}

	init() {
		this.bindAll()
		this.addPhoneCountry()
		setTimeout( () => {
			this.fields.forEach( (field) => {
				let input = field.querySelector('input.validation')
				input.addEventListener('change', this.fieldEvent)
				if(input.type !== 'checkbox' && input.value != '') this.fieldValidation(input)
			})
			this.btns.forEach( (btn) => {
				btn.addEventListener('click', this.changeActiveEvent)
			})
		}, 500)
	}
}

const checkoutContainer = document.querySelector('.booking_checkout')
const checkout = new BookingCheckout(checkoutContainer)

function guestyPay(data) {
	const container = document.querySelector('#guestyPay')
	if(container.innerHTML == '') {
		guestyTokenization.render({
			containerId: "guestyPay",
			providerId: "64e5142b42fff6004a2dcfae",
			amount: data['amount'],
			currency: "GBP",
			initialValues: {
				firstName: data['firstName'],
				lastName: data['lastName'],
			},
			sections: ["cardholderName", "billingAddress", "paymentDetails"]
		})
		let bookNowBtn = document.querySelector('#payNowBtn')
		bookNowBtn.classList.add('visible')
	}
}

function guestyPaySubmit(e) {
	const btn = e.srcElement,
	errorMessage = document.querySelector('.failedPayment')
	errorMessage.classList.remove('show')
	btn.classList.add('processing')

	//// Simulate Success
	//let token = '123'
	//checkout.guestyPaySuccess(token)
	//return
	
	let test = guestyTokenization.validate()
	
	let validate = guestyTokenization.submit()
	
	validate.then( (val) => checkout.guestyPaySuccess(val, btn, errorMessage), (reject) => rejectedPayment(reject, btn, errorMessage))
	//console.log(validate)
}

function rejectedPayment(reject, btn, errorMessage) {
	//console.log('reject', reject)
	errorMessage.classList.add('show')
	btn.classList.remove('processing')
}

let bookNowBtn = document.querySelector('#payNowBtn')
if(bookNowBtn) bookNowBtn.addEventListener('click', guestyPaySubmit)
