class TabbedContent {
	constructor(tabbed) {
		this.container = tabbed
		if(!this.container) return

		this.tabs = tabbed.querySelector('.tabs')
		this.tabItems = this.tabs.querySelectorAll('.tab')
		this.tabActive = this.tabs.querySelector('.active')

		if(this.tabItems.length < 2) return

		this.nav = tabbed.querySelector('.tabbed_nav')
		this.navItems = this.nav.querySelectorAll('span')
		this.navActive = this.nav.querySelector('.active')

		this.init()
	}
	bindAll() {
		["changeActiveEvent"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}

	changeActiveEvent(e) {
		let el = e.srcElement
		this.changeActive(el)
	}

	changeActive(el) {
		let tab = el.dataset.tab

		this.navActive.classList.remove('active')
		this.navActive = el
		this.navActive.classList.add('active')
		
		this.tabActive.classList.remove('active')
		this.tabActive = this.tabs.querySelector('#' + tab)
		this.tabActive.classList.add('active')
	}

	init() {
		this.bindAll()

		this.navItems.forEach((nav) => {
			nav.addEventListener('click', this.changeActiveEvent)
		})
	}
}

const tabs = document.querySelectorAll('.tabbed')
tabs.forEach( (tab) => {
	new TabbedContent(tab)
})