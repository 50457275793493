class BookingPrice {
	constructor(container) {
		this.container = container

		if(!this.container) return

		this.totalLabel = container.querySelector('.filter span.amount')

		this.target = this.container.querySelector('#breakdownAjax')
		this.loadingSpinner = container.querySelector('#loading_spinner')
		this.postUrl = '../../wp-content/themes/westay/ajax/booking-price.php'
		
		this.propertyID = container.dataset.id
		this.request = 'propertyID=' + this.propertyID

		this.http = new XMLHttpRequest()

	}

	showInfo() {
		this.container.classList.add('visible')
	}
	hideInfo() {
		this.container.classList.remove('visible')
	}

	getDateBreakdown(date) {
		let year = date.getFullYear(),
			month = ('0' + (date.getMonth() + 1)).slice(-2),
			day = ('0' + date.getDate()).slice(-2)
		
		return year + '-' + month + '-' + day 
	}

	getParams() {
		this.selectedStartDate = (window.localStorage.getItem(('startDateSelected'))) ? this.getDateBreakdown(new Date(window.localStorage.getItem(('startDateSelected')))) : ''
		this.selectedEndDate = (window.localStorage.getItem(('endDateSelected'))) ? this.getDateBreakdown(new Date(window.localStorage.getItem(('endDateSelected')))) : ''

		this.guestAdultsInt = (window.localStorage.getItem("filterAdults") != null) ? Number(window.localStorage.getItem("filterAdults")) : ''
		this.guestChildrenInt = (window.localStorage.getItem("filterChildren") != null) ? Number(window.localStorage.getItem("filterChildren")) : ''
		this.guestPetsInt = (window.localStorage.getItem("filterPet") != null) ? Number(window.localStorage.getItem("filterPet")) : ''
		this.guestTotal = this.guestAdultsInt + this.guestChildrenInt
		if(this.guestTotal == 0) this.guestTotal = 1
		this.buildRequest()
	}

	buildRequest() {
		this.request = 'propertyID=' + this.propertyID
		if(this.selectedStartDate != '' && this.selectedStartDate != null) this.request += '&startDate=' + this.selectedStartDate
		if(this.selectedEndDate != '' && this.selectedEndDate != null) this.request += '&endDate=' + this.selectedEndDate
		if(this.guestAdultsInt != '' && this.guestAdultsInt != null) this.request += '&guestAdult=' + this.guestAdultsInt
		if(this.guestChildrenInt != '' && this.guestChildrenInt != null) this.request += '&guestKid=' + this.guestChildrenInt
		if(this.guestTotal != '' && this.guestTotal != null) this.request += '&guestTotal=' + this.guestTotal
		if(this.guestPetsInt != '' && this.guestPetsInt != null) this.request += '&guestPet=' + this.guestPetsInt
	}

	displayPrice() {
		this.target.classList.add('transition')
		this.container.classList.add('loading')
		this.loadingSpinner.classList.add('loading')
		this.target.parentElement.classList.add('transition')
		if(bookingFormPost) bookingFormPost.newQuote()
		this.hideInfo()
		let t = this
		this.http.onreadystatechange = function () {
			if(this.readyState == 4) {
				if(t.http.responseText === '') { t.hideInfo() }
				
				t.target.innerHTML = t.http.responseText
				setTimeout(function() {
					if(t.http.responseText !== '') {
						let totalPriceSpan = t.target.querySelector('.total span'),
						totalPrice = totalPriceSpan.innerText || totalPriceSpan.innerHTML
						t.totalLabel.innerHTML = totalPrice
					}
				}, 100)
				setTimeout(function() {
					t.loadingSpinner.classList.remove('loading')
					t.container.classList.remove('loading')
					t.target.classList.remove('transition')
					t.target.parentElement.classList.remove('transition')
					t.showInfo()
					if(bookingFormPost) bookingFormPost.quoteIDUpdate()
				}, 200)
			}
		}
		this.http.open('POST', this.postUrl, true)
		this.http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
		this.http.send(this.request)
	}

	getInfo() {
		if(!this.container) return
		this.getParams()
		if(this.selectedStartDate != '' && this.selectedEndDate != '') {
			this.displayPrice()
		} else {
			this.hideInfo()
		}
	}
}

class BookingCoupon {
	constructor(container) {
		this.container = container
		if(!this.container) return

		this.input = this.container.querySelector('input')
		this.btn = this.container.querySelector('button')
		this.label = this.container.querySelector('.label')

		this.postUrl = '../../wp-content/themes/westay/ajax/coupon-code.php'

		this.http = new XMLHttpRequest()

		this.init()
	}

	bindAll() {
		["applyCoupon"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}

	applyCoupon() {
		let couponCode = this.input.value,
			quoteID = this.container.dataset.quoteId,
			labelText = '',
			t = this

		this.label.innerHTML = ''
		this.label.classList.remove('visible')

		if(couponCode !== '') {
			this.btn.classList.add('processing')
			couponCode = couponCode.toUpperCase()

			this.request = 'coupon=' + couponCode + '&quoteID=' + quoteID 

			this.http.onreadystatechange = function () {
				if(this.readyState == 4) {
					if(t.http.responseText == 'Coupon Applied') {
						labelText = couponCode + ' - coupon applied'
					} else {
						labelText = couponCode + ' - coupon not valid'
					}
					t.label.innerHTML = labelText
					t.label.classList.add('visible')
					t.btn.classList.remove('processing')
					t.input.value = ''
					quoteAjax.getQuote()
					termsAjax.getQuote()

					setTimeout( () => {
						t.label.classList.remove('visible')
					}, 3000)
				}
			}
			this.http.open('POST', this.postUrl, true)
			this.http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
			this.http.send(this.request)
		}
	}

	init() {
		this.bindAll()

		this.btn.addEventListener('click', this.applyCoupon)
	}
}

const couponContainer = document.querySelector('.coupon')
const coupon = new BookingCoupon(couponContainer)

class BookingQuote {
	constructor(container) {
		this.container = container
		if(!this.container) return 

		this.propertyId = this.container.dataset.propertyId
		this.quoteID = this.container.dataset.quoteId
		this.startDate = this.container.dataset.startDate
		this.guestPets = (window.localStorage.getItem("filterPet") != null) ? Number(window.localStorage.getItem("filterPet")) : ''

		this.request = 'propertyID=' + this.propertyId + '&quoteID=' + this.quoteID + '&startDate=' + this.startDate
		if(this.guestPets > 0) this.request += '&guestPet=' + this.guestPets

		this.postUrl = '../../wp-content/themes/westay/ajax/quote.php'
		this.http = new XMLHttpRequest()

		this.init()
	}

	getQuote() {
		let t = this

		this.http.onreadystatechange = function() {
			if(this.readyState == 4) {	
				t.container.innerHTML = t.http.responseText

				setTimeout( () => {
					let total = t.container.querySelector('.total span'),
						totalV = total.innerText,
						totalVal = totalV.replace('£', ''),
						amount = parseFloat(totalVal)
						checkout.setAmount(amount)
				}, 100)
			}
		}
		this.http.open('POST', this.postUrl, true)
		this.http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
		this.http.send(this.request)
	}

	init() {
		this.getQuote()
	}

}

const quoteContainer = document.querySelector('#quoteAjax')
const quoteAjax = new BookingQuote(quoteContainer)

class PaymentTerms {
	constructor(container) {
		this.container = container
		if(!this.container) return 

		this.propertyId = this.container.dataset.propertyId
		this.quoteID = this.container.dataset.quoteId
		this.startDate = this.container.dataset.startDate
		this.guestPets = (window.localStorage.getItem("filterPet") != null) ? Number(window.localStorage.getItem("filterPet")) : ''

		this.petRequest = 'propertyID=' + this.propertyId + '&guestPet=' + this.guestPets

		this.request = 'quoteID=' + this.quoteID

		this.today = new Date()

		this.postUrl = '../../wp-content/themes/westay/ajax/payment-terms.php'
		this.petUrl = '../../wp-content/themes/westay/ajax/pet-fee.php'
		this.http = new XMLHttpRequest()
		this.petHttp = new XMLHttpRequest()

		this.depositLen = 7
		this.depositPercent = 30

		this.init()
	}

	getQuote() {
		this.getPetFee()
		let t = this

		this.http.onreadystatechange = function() {
			if(this.readyState == 4) {	
				t.data = JSON.parse(t.http.response)
				t.updateTerms()
			}
		}
		this.http.requestType = 'json'
		this.http.open('POST', this.postUrl, true)
		this.http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
		this.http.send(this.request)
	}

	checkInDistance(start, end) {
		let length = end.getTime() - start.getTime(),
		lenInDays = length / (1000 * 60 * 60 * 24)
		lenInDays = Math.ceil(lenInDays)
		return lenInDays
	}

	getPetFee() {
		let t = this

		this.petHttp.onreadystatechange = function() {
			if(this.readyState == 4) {
				t.petFee = parseFloat(t.petHttp.responseText)
			}
		}
		this.petHttp.open('POST', this.petUrl, true)
		this.petHttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
		this.petHttp.send(this.petRequest)
	}

	english_ordinal_suffix(dt) {
		return dt.getDate()+(dt.getDate() % 10 == 1 && dt.getDate() != 11 ? 'st' : (dt.getDate() % 10 == 2 && dt.getDate() != 12 ? 'nd' : (dt.getDate() % 10 == 3 && dt.getDate() != 13 ? 'rd' : 'th'))); 
	}
	
	dateString(dateIn) {
		let year = dateIn.getFullYear(),
			month = dateIn.getMonth(),
			date = dateIn.getDate(),
			day = dateIn.getDay(),
			suffix = this.english_ordinal_suffix(dateIn)
		let weekDay = isNaN(day) ? null : ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'][day]
		let monthName = isNaN(month) ? null : [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ][month]
		return weekDay + ' ' + suffix + ' ' + monthName + ' ' + year
	}

	setRange() {
		let checkinDate = new Date(this.startDate),
			daysTilCheckin = this.checkInDistance(this.today, checkinDate)
		this.terms = []
		if(daysTilCheckin > this.depositLen) {
			let deposit =  (this.total / 100 * this.depositPercent).toFixed(2),
				finalPaymentDate = new Date(this.startDate)
			
			finalPaymentDate.setDate(finalPaymentDate.getDate()-7)

			let todayDateString = this.dateString(this.today),
				finalPayString = this.dateString(finalPaymentDate)

			this.terms[todayDateString] = deposit
			this.terms[finalPayString] = (this.total - deposit).toFixed(2)
		} else {
			let todayDateString = this.dateString(this.today)
			this.terms[todayDateString] = this.total.toFixed(2)
		}
		this.renderTerms()
	}

	renderTerms() {
		this.container.innerHTML = ''
		let termsUL = document.createElement('ul')
		this.container.appendChild(termsUL)
		Object.entries(this.terms).forEach( ([date, value]) => {
			let entry = document.createElement("li")
			let text = date
			let valueInfo = '£' + value
			let entryText = document.createTextNode(text)
			let valueText = document.createTextNode(valueInfo)
			
			let entryValue  = document.createElement('span')
			entryValue.appendChild(valueText)
			entry.appendChild(entryText)
			entry.appendChild(entryValue)
			termsUL.appendChild(entry)
		})

		checkout.addData('quoteID', this.quoteID)
		checkout.addData('ratePlanID', this.ratePlanId)

	}

	updateTerms() {
		
		let ratePlans = this.data.rates.ratePlans
		ratePlans.forEach( (plan) => {
			this.ratePlan = plan.ratePlan
			this.ratePlanId = this.ratePlan._id
			this.money = this.ratePlan.money
			this.invoiceItems = this.money.invoiceItems
			this.total = 0
			this.invoiceItems.forEach( (item) => {
				let amount = item.amount
				this.total += amount
			})
			if(this.guestPets > 0) this.total += this.petFee
		})
		this.setRange()
	}

	init() {
		this.getQuote()
	}

}

const termsContainer = document.querySelector('#paymentTerms')
const termsAjax = new PaymentTerms(termsContainer)