const collection_swiper = new Swiper(".collectionSwiper", {
	slidesPerView: 'auto',
	spaceBetween: 30,
	freeMode: true,
	grabCursor: true,
	scrollbar: {
        el: ".swiper-scrollbar",
        hide: true,
		draggable: true,
		dragSize: 100,
		hide: false,
	},
	navigation: {
		enabled: true,
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

const isHome = document.querySelector('.home .calendar')
let calendarOptions = {
	slidesPerView: 1,
	spaceBetween: 30,
	wrapperClass: 'months',
	slideClass: 'month',
	freeMode: true,
	shortSwipes: false,
	simulateTouch: false,
	grabCursor: true,
	breakpoints: {
		768: {
			slidesPerView: 2,
		},
		1320: {
			slidesPerView: 3,
		}
	}
}
if(isHome) {
	calendarOptions = {
		slidesPerView: 1,
		spaceBetween: 30,
		wrapperClass: 'months',
		slideClass: 'month',
		freeMode: true,
		grabCursor: true,
		shortSwipes: false,
		simulateTouch: false,
		breakpoints: {
			768: {
				slidesPerView: 2,
			},
			1020: {
				slidesPerView: 2,
			}
		}
	}
}

const testimonial_swiper = new Swiper(".testimonials_carousel", {
	slidesPerView: 1,
	spaceBetween: 30,
	grabCursor: true,
	loop: true,
	centeredSlides: true,
	navigation: {
		enabled: true,
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination: {
		el: ".swiper-pagination",
		clickable: true,
	},
	//autoplay: {
	//	delay: 4500,
	//	disableOnInteraction: false,
	//	pauseOnMouseEnter: true,
	//},
})